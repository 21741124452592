import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes, { object } from 'prop-types';
import Dropzone from 'react-dropzone';
import BrowserImageSize from 'browser-image-size';

//Helpers
import fileTypes from 'data/filetypes.json';
import imageTypes from 'data/imagetypes.json';
import removeDuplicates from 'components/_helpers/removeDuplicates';
import LoadingIndicatorAtom from 'components/atoms/LoadingIndicator/Default';

//Components
import ButtonAtom from 'components/atoms/Buttons/Button';
import FileListMolecule from 'components/molecules/Lists/FileList';
import NotificationAtom from 'components/atoms/Notifications/Notification';
import AvatarAtom from 'components/atoms/Images/Avatar';
import { useDispatch } from 'react-redux';
import style from 'assets/stylesheets/molecules/External/FileUploader';

import dictionary from 'data/dictionary.json';
import { scanDocumentAction, scanKYCDocumentAction, scanRequestDocumentAction } from '../../../redux/actions';
import { Workbook } from 'exceljs';
const { fileUploader, imageUploader } = dictionary;

const FileUploader = ({
    allowMultiple,
    handleDrop,
    droppedFiles,
    uploaderType,
    isDisabled,
    hasHandleDropCheck,
    handleDropCheck,
    children,
    dropObject,
    isEditMode,
    showFiles,
    payloadDocument,
    malwareData,
    scannedDocuments,
    apiError,
    timeoutError,
    isDocument,
    isFileChanged,
    clientUpload,
    scannedAvatar
}) => {
    const { t } = useTranslation();
    const MAX_SIZE = 62914560; // 60 Mb in byte
    const MIN_SIZE = 1024; // 1 kb in byte
    const dropzoneRef = React.createRef();
    const isImageUploader = uploaderType === 'image';
    const isKYC = isDocument === 'KYC';
    const isRequestedDocument = isDocument === 'RequestDocument';
    const [isLoading, setIsLoading] = useState(false);
    const [excelFilesToRemove, setExcelFilesToRemove] = useState([]);
    const getFileTypes = (typesFile) => {
        let results = typesFile.map((value) => value.mimes);
        return results.toString();
    };
    const dispatch = useDispatch();
    const [errorMessages, setErrorMessages] = useState([]);
    const [count, setCount] = useState(false);
    const showDropZone = !isEditMode || !showFiles;
    const [config, setConfig] = useState({
        image: {
            fileTypes: getFileTypes(imageTypes),
            content: imageUploader,
            allowMultiple: false,
        },
        file: {
            fileTypes: getFileTypes(fileTypes),
            content: fileUploader,
            allowMultiple: allowMultiple,
        },
    });
    const maxFiles = !config[uploaderType].allowMultiple && scannedDocuments.length;
    useEffect(
        () => () => {
            // Revoke the data uris to avoid memory leaks
            if (isImageUploader) droppedFiles.forEach((file) => URL.revokeObjectURL(file.preview));
            setExcelFilesToRemove([]);
        },
        [droppedFiles, isImageUploader]
    );

    const openDialog = (e) => {
        e.stopPropagation();
        // ref is set async, so it might be null at some point
        if (dropzoneRef.current) dropzoneRef.current.open();
    };

    const documentOrImage = isImageUploader ? scannedAvatar : scannedDocuments;

    const handleRemoveFile = (file) => {
        const array = documentOrImage.filter((item) => item.name !== file.name);
        handleDrop(array, 'Delete_File');
    };

    const sizeLabel = isImageUploader ? 'csp.uploader.image.typeAndSize' : 'csp.uploader.file.sizeLimit';

    const dispatchActionObject = (payload) => {
        let action;
        if ((!isEditMode || isFileChanged) && isKYC && payload?.amount > 0) {
            action = scanKYCDocumentAction(payload);
            dispatch(action);
        } else if (payload?.amount > 0) {
            action = scanDocumentAction(payload);
            dispatch(action);
        }
        setCount(false);
        handleDrop([]);
    };

    const dispatchRequestDocumentAction = (files) => {
        if (files.length > 0) {
            dispatch(scanRequestDocumentAction({ files, clientUpload }));
            setCount(false);
        }
    };

    useEffect(() => {
        if (count) {
            if (isRequestedDocument) {
                dispatchRequestDocumentAction(droppedFiles);
            } else if (payloadDocument) {
                dispatchActionObject(payloadDocument);
            } else {
                setCount(false);
            }
        }
    }, [droppedFiles]);

    useEffect(() => {
        setIsLoading(false);
    }, [scannedDocuments, apiError, malwareData, timeoutError, scannedAvatar]);

    const checkForFormulas = (e, reader, file) => {
        const file_name = file?.name;
        const workBook = new Workbook();
        var contents = e.target.result;
        try {
            workBook.xlsx.load(reader.result).then(wb => {
                wb.eachSheet((sheet, id) => {
                    sheet.eachRow((row, rowIndex) => {

                        const rowValues = row.values;
                        const hasFormulas = rowValues.some((colValue) => typeof colValue === 'object');
                        if (hasFormulas) {
                            console.log(`${file_name} has forumlas`)
                            setErrorMessages(
                                `${file_name} has got formulas, hence cannot progress with uploading`
                            );

                            setIsLoading(false);
                            excelFilesToRemove.push(file_name)
                            handleDrop([]);
                            throw `${file_name} has got formulas, hence cannot progress with uploading`
                        }
                    })
                })
            });
        } catch (error) { console.log(error) }


    }
    return (
        <>
            <section className={style.fileuploader}>
                {showDropZone && (
                    <Dropzone
                        ref={dropzoneRef}
                        onDrop={(files) => {
                            files.map(file => {
                                const fileName = file?.name;
                                if (fileName.includes(".xlsx") ||
                                    fileName.includes(".xls")) {
                                    var reader = new FileReader();
                                    reader.readAsArrayBuffer(file)
                                    reader.onload = function (e) { checkForFormulas(e, reader, file) }
                                }
                            })
                            setCount(true);
                            setIsLoading(true);
                            if (isImageUploader) {
                                const acceptedFiles = files.map((file) => {
                                    BrowserImageSize(file).then((size) => {
                                        if (size.width > 240 && size.height > 240) {
                                            setErrorMessages(
                                                `${file.name} ${t('csp.uploader.tooLargeError')} ${t(sizeLabel)}`
                                            );
                                            setIsLoading(false);
                                            return handleDrop([]);
                                        }
                                    });
                                    return Object.assign(file, { preview: URL.createObjectURL(file) });
                                });
                            }
                            if (hasHandleDropCheck) {
                                const { isError, errorMessage } = handleDropCheck(files);
                                if (isError) {
                                    setErrorMessages(errorMessage);
                                    setIsLoading(false);
                                    return handleDrop([]);
                                }
                            }
                            let acceptedFiles = removeDuplicates([...files, ...droppedFiles]);
                            for (var i = 0; i <= excelFilesToRemove.length; i++) {
                                if (acceptedFiles.indexOf(excelFilesToRemove[i]))
                                    acceptedFiles = acceptedFiles.splice(acceptedFiles.indexOf(excelFilesToRemove[i]), 1);
                            }

                            setErrorMessages([]);
                            return handleDrop(acceptedFiles);
                        }}
                        onDropRejected={(rejected) => {
                            let errorCodes = [];
                            let rejectedFiles = [];

                            rejected.forEach((item) => {
                                rejectedFiles = [...rejectedFiles, item.file.name];
                                errorCodes = [...errorCodes, ...item.errors.map((err) => err.code)];
                                return;
                            });

                            setIsLoading(false);

                            switch (true) {
                                case errorCodes.includes('too-many-files'):
                                    return setErrorMessages(t('csp.uploader.maxFilesError'));
                                case errorCodes.includes('file-too-small'):
                                    return setErrorMessages(t('csp.uploader.tooSmallError'));
                                case errorCodes.includes('file-invalid-type'):
                                    return setErrorMessages(
                                        `${rejectedFiles.join(', ')} 
                                ${t('csp.uploader.typeError')} 
                                ${t(sizeLabel)}`
                                    );
                                default:
                                    return setErrorMessages(
                                        `${rejectedFiles.join(', ')} 
                                ${t('csp.uploader.tooLargeError')} 
                                ${t(sizeLabel)}`
                                    );
                            }
                        }}
                        accept={config[uploaderType].fileTypes}
                        maxSize={MAX_SIZE}
                        minSize={MIN_SIZE}
                        disabled={isDisabled}
                        multiple={config[uploaderType].allowMultiple}>
                        {({ getRootProps, getInputProps, isDragActive }) => {
                            return maxFiles ? null : (
                                <div
                                    className={`${style.fileuploader__dropzone} ${isDisabled ? style['fileuploader__dropzone--disabled'] : ''
                                        } ${!!isDragActive && style['fileuploader__dropzone--active']}`}
                                    {...getRootProps()}>
                                    <>
                                        <input {...getInputProps()} />
                                        {!!errorMessages.length && (
                                            <NotificationAtom type="error" isInline={true}>
                                                {errorMessages}
                                            </NotificationAtom>
                                        )}
                                        {children}
                                        <div className={style.fileuploader__dropzone__buttons}>
                                            {isDragActive ? (
                                                <span>{config[uploaderType].content.dragActiveMsg}</span>
                                            ) : (
                                                <>
                                                    <ButtonAtom
                                                        buttonStyle="secondary"
                                                        onClick={openDialog}
                                                        isDisabled={isDisabled}>
                                                        {config[uploaderType].content.addFileBtn}
                                                    </ButtonAtom>
                                                    <span>{config[uploaderType].content.dragFileTxt}</span>
                                                </>
                                            )}
                                        </div>
                                    </>
                                </div>
                            )
                        }
                        }
                    </Dropzone>
                )}

                {isLoading &&
                    <LoadingIndicatorAtom />
                }
                {malwareData?.length > 0 && (
                    <NotificationAtom type="error">
                        {t('csp.uploader.malwareError')}
                    </NotificationAtom>
                )}
                {timeoutError && (
                    <NotificationAtom type="error">
                        {t('csp.uploader.timeoutError')}
                    </NotificationAtom>
                )}
                {apiError && (
                    <NotificationAtom type="error">
                        {t('csp.uploader.apiError')}
                    </NotificationAtom>
                )}
                {(!!documentOrImage.length || showFiles) && (
                    <>
                        {isImageUploader &&
                            droppedFiles.map((file, i) => (
                                <AvatarAtom key={i} src={file.preview} alt="User profile avatar preview" />
                            ))}
                        <FileListMolecule
                            files={!isEditMode || !showFiles ? documentOrImage : dropObject}
                            canDelete={true}
                            {...{ handleRemoveFile }}
                        />
                    </>
                )}
            </section >
        </>
    );
};

const { bool, func, array, node, string } = PropTypes;

FileUploader.propTypes = {
    allowMultiple: bool,
    handleDrop: func,
    droppedFiles: array,
    children: node,
    uploaderType: string,
    scannedDocuments: array
};

FileUploader.defaultProps = {
    allowMultiple: true,
    handleDrop: () => { },
    droppedFiles: [],
    children: null,
    uploaderType: 'file',
    scannedDocuments: []
};

export default FileUploader;
